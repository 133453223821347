import {Pipe, PipeTransform} from '@angular/core';
import {TenantService} from "../../tenant/services/tenant.service";

@Pipe({
  name: 'routeTenant'
})
export class RouteTenantPipe implements PipeTransform {
  constructor(private tenantService: TenantService) {}

  transform(route: string, tenant: string | null = null): string {
    return this.tenantService.getTenantRoute(route, tenant);
  }
}
