import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RefreshUserGuard} from "./shared/auth/guards/refresh-user.guard";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./tenant/tenants/talentview/talentview-tenant.module').then(m => m.TalentviewTenantModule)
      },
      /*{
        path: 'construction',
        pathMatch: 'prefix',
        loadChildren: () => import('./tenant/tenants/construction/construction-tenant.module').then(m => m.ConstructionTenantModule)
      },
      {
        path: 'adsfutures',
        pathMatch: 'prefix',
        loadChildren: () => import('./tenant/tenants/adsfutures/adsfutures-tenant.module').then(m => m.AdsfuturesTenantModule)
      },
      {
        path: 'aviation',
        pathMatch: 'prefix',
        loadChildren: () => import('./tenant/tenants/aviation/aviation-tenant.module').then(m => m.AviationTenantModule)
      },*/
      // redirect to home
      {
        path: '**',
        redirectTo: '/account/sign-in'
      }
    ],
    canActivate: [RefreshUserGuard]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
