import {RouteTenantPipe} from "../../shared/pipes/route-tenant.pipe";
import {NgModule} from "@angular/core";

@NgModule({
  declarations: [
    RouteTenantPipe
  ],
  imports: [
  ],
  exports: [
    RouteTenantPipe
  ],
  providers: [
    RouteTenantPipe
  ]
})
export class TenantUtilsModule {}
