import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterComponent} from "../../components/footer/footer.component";
import {HeaderComponent} from "../../components/header/header.component";
import {TopNavigationComponent} from "../../components/navigations/top-navigation/top-navigation.component";
import {RouterModule} from "@angular/router";
import {GraphicModule} from "../graphic/graphic.module";
import {HeaderModule} from "./header.module";

@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    RouterModule,
    GraphicModule
  ],
  exports: [
    RouterModule,
    GraphicModule,
    HeaderModule,
    FooterComponent,
  ]
})
export class HeaderAndFooterModule { }
