import {Injectable, Injector} from '@angular/core';
import {TenantConfig} from "../models/TenantConfig";
import {Tenant, TenantService} from "./tenant.service";
import {Router} from "@angular/router";
import {RefreshUserGuard} from "../../shared/auth/guards/refresh-user.guard";
import {EnvironmentService} from "../../shared/services/environment.service";
import {TenantConfigList} from "../models/TenantConfigList";

@Injectable()
export class TenantConfigService {

  tenant: TenantConfig = TenantConfigList.defaultTenantConfig;
  tenantConfigs: any = {};

  constructor(private tenantService: TenantService, private injector: Injector, private environmentService: EnvironmentService) {}

  load(): Promise<boolean> {

    return new Promise<boolean>((resolve) => {

      const currentTenant = this.tenantService.getTenant();
      this.tenant = this.getTenantConfigByTenantEnvironment(this.tenantService.getTenant(), this.environmentService.environmentByHost(currentTenant));
      this.tenantService.setTenantConfig(this.tenant);
      this.environmentService.setTenantConfig(this.tenant);

      // Update tenant routes
      let routes: any[] = [];

      if(this.tenant.tenant === Tenant.TALENTVIEW) {
        routes = [
          {
            path: '',
            children: [
              {
                path: '',
                loadChildren: () => import('./../tenants/talentview/talentview-tenant.module').then(m => m.TalentviewTenantModule)
              },
              // redirect to home
              {
                path: '**',
                redirectTo: '/account/sign-in'
              }
            ],
            canActivate: [RefreshUserGuard]
          },
        ];
      } else if(this.tenant.tenant === Tenant.DNCP) {
        routes = [
          {
            path: '',
            children: [
              {
                path: '',
                loadChildren: () => import('./../tenants/destination-nuclear/destination-nuclear-tenant.module').then(m => m.DestinationNuclearTenantModule)
              },
              // redirect to home
              {
                path: '**',
                redirectTo: '/'
              }
            ],
            canActivate: [RefreshUserGuard]
          },
        ];
      }

      const router: Router = this.injector.get(Router);
      router.resetConfig(routes);
      resolve(true);
    });

  }

  getTenantConfigByTenantEnvironment(tenant: string | null, environment: string): TenantConfig {
    if (!tenant) {
      return TenantConfigList.defaultTenantConfig;
    }

    console.log("Available tenant configs: ", TenantConfigList.get());
    console.log("Current Tenant Environment: ", tenant, environment);
    return TenantConfigList.get()[tenant].environments[environment];
  }

}
