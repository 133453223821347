import {Injectable} from '@angular/core';
import {NavigationEnd, Event, NavigationError, Router, ActivatedRoute, NavigationStart} from "@angular/router";
import {BreadcrumbService} from "../../shared/breadcrumb/service/breadcrumb.service";
import {TenantConfigService} from "./tenant-config.service";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService, private tenantConfigService: TenantConfigService) {

    router.events.subscribe( (event: Event) => {

      if (event instanceof NavigationEnd) {
        this.navigationEnd(event);
      }

      if (event instanceof NavigationStart) {
        this.navigationStart(event);
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
      }
    });
  }

  navigationEnd(event: NavigationEnd) {
    // Scroll to the top of the page on navigate
    //this.tenantConfigService.load();
    //console.log("load navigation End");

  }

  navigationStart(event: NavigationStart) {
    // If breadcrumb exists in the route config we load it. It can be overrided in components.
    this.parseRouteBreadcrumb();
  }

  parseRouteBreadcrumb() {
    this.breadcrumbService.clear();
  }

}
