import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError, Observable, throwError} from "rxjs";
import {AuthService} from "../auth/services/auth.service";
import {BasicModalComponent} from "../components/modals/basic-modal/basic-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService, private modal: NgbModal, private router: Router) {}

  intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(httpRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.router.navigate(['/account/sign-in']);
        }

        if (error.status === 500) {
          const modalRef = this.modal.open(BasicModalComponent);
          modalRef.componentInstance.title = 'Error';
          modalRef.componentInstance.body = 'Something went wrong, please try again later!';
        }

        return throwError(error);
      }));
  }

}
