import { Injectable } from '@angular/core';
import {BreadcrumbItem} from "../model/breadcrumb-item";
import {Observable, BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BreadcrumbService {

    private dataStore: {
        breadcrumbs: BreadcrumbItem[]
    };

    breadcrumbs: Observable<BreadcrumbItem[]>;
    private _breadcrumbs: BehaviorSubject<BreadcrumbItem[]> = new BehaviorSubject<BreadcrumbItem[]>([]);

    constructor() {
        this.dataStore = { breadcrumbs: [] };
        this.breadcrumbs = this._breadcrumbs.asObservable();
    }

    add(breadcrumb: BreadcrumbItem) {
        this.dataStore.breadcrumbs.push(breadcrumb);
        this._breadcrumbs.next(Object.assign({}, this.dataStore).breadcrumbs);
    }

    clear() {
        this.dataStore.breadcrumbs = [];
        this._breadcrumbs.next(Object.assign({}, this.dataStore).breadcrumbs);
    }

}
