import {JsonAdapter} from "../helpers/JsonAdapter";

export class NavigationItem {
  title: string = "";
  routerLink: string = "";
  visibleIfGuest = false;
  visibleIfMember = false;

  static create(object:any): NavigationItem {
    const item = JsonAdapter.fill(new NavigationItem(), object);

    return item;
  }
}
