import { Injectable } from '@angular/core';
import {TenantConfigService} from "../../tenant/services/tenant-config.service";
import {environment} from "../../../environments/environment";
import {Tenant} from "../../tenant/services/tenant.service";
import {TenantConfigList} from "../../tenant/models/TenantConfigList";
import {TenantConfig} from "../../tenant/models/TenantConfig";

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  defaultEnvironment = 'production';
  tenantConfig: TenantConfig | null = null;

  constructor() {}

  setTenantConfig(tenantConfig: TenantConfig) {
    this.tenantConfig = tenantConfig;
  }

  get apiBaseUrl(): string {
    return this.tenantConfig?.apiBaseUrl || environment.apiBaseUrl;
  }

  get apiV1BaseUrl(): string {
    return this.tenantConfig?.apiV1BaseUrl || environment.apiV1BaseUrl;
  }

  public environmentByHost(tenant: Tenant): string {
    const list = TenantConfigList.get();
    for(let i in list[tenant].environments) {
      if(list[tenant].environments[i].hosts.indexOf(window.location.hostname) >= 0) {
        return i;
      }
    }

    return this.defaultEnvironment;
  }
}
