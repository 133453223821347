import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from "../../components/header/header.component";
import {TopNavigationComponent} from "../../components/navigations/top-navigation/top-navigation.component";
import {RouterModule} from "@angular/router";
import {GraphicModule} from "../graphic/graphic.module";

@NgModule({
  declarations: [
    HeaderComponent,
    TopNavigationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    GraphicModule
  ],
  exports: [
    RouterModule,
    GraphicModule,
    HeaderComponent,
    TopNavigationComponent
  ]
})
export class HeaderModule { }
