import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { config } from '@fortawesome/fontawesome-svg-core';
import {AuthService} from "./shared/auth/services/auth.service";
import {User} from "./shared/models/User";
import {BehaviorSubject, Observable} from "rxjs";
import {isPlatformBrowser} from "@angular/common";
import {ThemeService} from "./shared/services/theme.service";
import {ActivatedRoute} from "@angular/router";
import {RouteListenerService} from "./shared/services/route-listener.service";
import {NavigationService} from "./tenant/services/navigation.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  title = 'talentview-v2';
  static isBrowser = new BehaviorSubject<boolean>(false);

  constructor(private authService: AuthService, @Inject(PLATFORM_ID) private platformId: any,
              private themeService: ThemeService, private routeListenerService: RouteListenerService,
              private navigationService: NavigationService) {

    // Check if the app is running on the browser because of SSR
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));

    // Prevent fontawesome from adding its CSS since we did it manually above:
    config.autoAddCss = false; /* eslint-disable import/first */
  }

  ngOnInit(): void {

  }
}
