import { Injectable } from '@angular/core';
import {Event, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {ThemeService} from "./theme.service";
import {TenantService} from "../../tenant/services/tenant.service";
import {TenantConfigService} from "../../tenant/services/tenant-config.service";
import {BreadcrumbService} from "../breadcrumb/service/breadcrumb.service";

@Injectable({
  providedIn: 'root'
})
export class RouteListenerService {

  constructor(private router: Router,
              private themeService: ThemeService,
              private tenantService: TenantService,
              private tenantConfigService: TenantConfigService,
              private breadcrumbService: BreadcrumbService) {

    router.events.subscribe((event: Event) => {
      // see also
      if (event instanceof NavigationStart) {
        /*const currentTenant = this.tenantService.getTenant();
        const newTenant = this.tenantService.getTenant(event.url);

        console.log("current", currentTenant);
        console.log("new", newTenant);
        console.log("event", event);

        if(currentTenant !== newTenant) {
          this.themeService.setTheme(newTenant + "");
          this.tenantConfigService.load();
        }*/
      } else if(event instanceof NavigationEnd) {

      }
    });
  }
}
