import {Injectable, RendererFactory2, Renderer2, Inject, PLATFORM_ID} from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {TenantService} from "../../tenant/services/tenant.service";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private theme$: BehaviorSubject<string> = new BehaviorSubject('default');

  private _renderer: Renderer2;
  private head: HTMLElement;
  private themeLinks: HTMLElement[] = [];

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) document: Document,
              private tenantService: TenantService, @Inject(PLATFORM_ID) platformId: Object) {

    this.head = document.head;
    this._renderer = rendererFactory.createRenderer(null, null);

    if(!isPlatformBrowser(platformId)) {
      return;
    }

    console.log("Current Tenant", tenantService.getTenant());

    this.theme$.subscribe(async (theme) => {
      if(theme === 'talentview') {
        theme = 'default';
      }
      const cssExt = '.css';
      const cssFilename = 'theme-' + theme + cssExt;
      await this.loadCss(cssFilename);
    });

    this.setTheme(tenantService.getTenant());

  }

  setTheme(name: string) {
    if(name) {
      this.theme$.next(name);
    }
  }

  private async loadCss(filename: string) {
    return new Promise(resolve => {

      this.removeThemeLinks();

      const linkEl: HTMLElement = this._renderer.createElement('link');
      this._renderer.setAttribute(linkEl, 'rel', 'stylesheet');
      this._renderer.setAttribute(linkEl, 'type', 'text/css');
      this._renderer.setAttribute(linkEl, 'href', filename);
      this._renderer.setProperty(linkEl, 'onload', resolve);
      this._renderer.appendChild(this.head, linkEl);
      this.themeLinks = [...this.themeLinks, linkEl];
    })
  }

  private removeThemeLinks() {
    this.themeLinks.forEach(linkEl => {
      this._renderer.removeChild(this.head, linkEl);
    })
    this.themeLinks = [];
  }
}
