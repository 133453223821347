export class JsonAdapter {

    static allowedObjects = ["boolean", "string", "number"];

    static fill(instance:any, object:any) {
        Object.keys(object).forEach(key => {
            if(JsonAdapter.allowedObjects.indexOf(typeof object[key]) > -1 && instance.hasOwnProperty(key)) {
                instance[key] = object[key];
            }
        });

        return instance;
    }
}
