import {DOCUMENT, isPlatformServer, Location} from '@angular/common';
import { HttpHeaders } from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {TenantConfigService} from "./tenant-config.service";
import {TenantConfig} from "../models/TenantConfig";

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  private defaultTenant = Tenant.TALENTVIEW;
  public allowedTenants = [Tenant.TALENTVIEW, Tenant.CONSTRUCTION];
  private tenantDomainMap:any[] = [
    {
      name: 'Destination Nuclear',
      slug: 'destination-nuclear',
      hosts: [
        'phase2.destinationnuclearcareersportal.co.uk',
        'dev.destinationnuclearcareersportal.co.uk',
        'dev.dncp.test',
        'phase2.dncp.test'
      ]
    }
  ]

  public tenantConfig: TenantConfig | null = null;

  constructor(@Inject(DOCUMENT) private document: any) {}

  setTenantConfig(config: TenantConfig) {
    this.tenantConfig = config;
  }

  getTenantByUrl(url: string | null = null): Tenant | null {
    if(window.location.hostname.indexOf('talentview.org') < 0) {
      return this.getTenantForString(this.getTenantByHost(window.location.hostname));
    }

    const path = (url || this.document.location.pathname).trim('/').split('/');
    return this.getTenantForString(path[1] || '');
  }

  getTenantByHost(host: string): string {
    for (let i = 0; i < this.tenantDomainMap.length; i++) {
      if(this.tenantDomainMap[i].hosts.indexOf(host) >= 0) {
        return this.tenantDomainMap[i].slug;
      }
    }
    return '';
  }

  getTenantForParam(param: string): Tenant | null {
    return this.getTenantForString(param);
  }

  getTenantForString(s: string) {
    if(s) {
      const values = Object.values(Tenant);
      for (let i = 0; i < values.length; i++) {
        if (values[i].toLowerCase() === s.toLowerCase()) {
          return values[i] as Tenant;
        }
      }
    }
    return null;
  }

  getTenant(route: string | null = null): Tenant {
    return this.getTenantByUrl(route) || this.defaultTenant;
  }

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {

    let tenant = this.getTenant();
    if(tenant) {
      return headers.append("X-Tenant-ID", tenant);
    }

    return headers;
  }

  getTenantRoute(route: string, tenant: string | null = null): string {
    route = route.replace(/^\/|\/$/g, '');

    if(this.tenantConfig) {
      return this.tenantConfig.baseUrl + route;
    }

    tenant = this.getTenantByUrl();

    if(tenant) {
      return '/' + tenant + "/" + route;
    }

    return '/' + route;
  }
}

export enum Tenant {
  TALENTVIEW = "talentview",
  CONSTRUCTION = "construction",
  AVIATION = "aviation",
  ADSFUTURES = "adsfutures",
  DNCP = "destination-nuclear"
}
