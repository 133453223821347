import {JsonAdapter} from "../helpers/JsonAdapter";
import {environment} from "../../../environments/environment";

export class Organisation {
  id: string = "";
  name: string = "";
  slug: string = "";

  static create(object:any): Organisation {
    const item = JsonAdapter.fill(new Organisation(), object);

    return item;
  }

  static createMultiple(objects: Organisation[]): Organisation[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(Organisation.create(objects[i]));
    }
    return items;
  }

  get logo() {
    return environment.apiV1BaseUrl + 'organisation/' + this.id + '/logo/current';
  }

  get jobsUrl() {
    return '/organisation-hub/' + (this.slug || this.id) + '/jobs';
  }

  get url() {
    return '/organisation-hub/' + (this.slug || this.id);
  }
}
